<template>
  <div v-if="meStore.currency.basename">
    <EssentialsDefaultButtonEvent :class="{ 'vplus-style': vplus }" @click="register()">
      <slot>{{ $t('buttons.register_now') }}</slot>
    </EssentialsDefaultButtonEvent>
  </div>
</template>

<script>
import { mapStores } from 'pinia';

export default {
  props: {
    vplus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useMeStore, useMainStore),
    platform() {
      return this.mainStore.platform;
    },
    uri_scheme() {
      return this.mainStore.uri_scheme;
    },
    path() {
      if (this.vplus) {
        return 'vplus-registration-form';
      }
      return 'registration-form';
    },
  },
  methods: {
    register() {
      if (this.platform !== 'mobileweb' && this.platform !== 'desktop') {
        window.location.href = `${this.uri_scheme}registration-form`;
      } else {
        let url = `${this.uri_scheme}${this.path}$`;

        if (!this.vplus) {
          this.$track('notification/register');
          url += this.$route.path;
        }

        window.parent.postMessage(url, this.mainStore.frontend_url);
      }
    },
  },
};
</script>

<style scoped>
.vplus-style {
  @apply w-full border-white text-white;
  background: #e3051b;
}

.vplus-style:hover {
  @apply bg-white;
  color: #e3051b;
  border-color: #e3051b;
}
</style>
